<template>
  <section class="cart-section section-b-space">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <table
            class="table cart-table table-responsive-xs"
            v-if="cart.length"
          >
            <thead>
              <tr class="table-head">
                <th scope="col">image</th>
                <th scope="col">Article</th>
                <th scope="col">Prix U.</th>
                <th scope="col">Point U.</th>
                <th scope="col">Qte</th>
                <!--                            <th scope="col">action</th>-->
                <th scope="col">total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in cart" :key="index">
                <td>
                  <router-link :to="{ path: '/produit/' + item.id }">
                    <img :src="item.images[0].original_url" alt="" />
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ path: '/produit/' + item.id }">{{
                    item.name
                  }}</router-link>
                  <div class="mobile-cart-content row">
                    <div class="col-xs-3">
                      <h2 class="td-color">{{ item.product_price }}</h2>
                      <!--                      <h2 class="td-color">{{ item.discount }}</h2>
                                            <h2 class="td-color">{{ item.point }}</h2>-->
                    </div>
                    <div class="col-xs-3">
                      <div class="qty-box">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                              type="button"
                              class="btn quantity-left-minus"
                              data-type="minus"
                              data-field
                              @click="decrement(item)"
                            >
                              <i class="ti-angle-left"></i>
                            </button>
                          </span>
<!--                          :disabled="item.quantity > item.stock"-->
                          <input
                            type="text"
                            name="quantity"
                            class="form-control input-number"
                            :disabled="true"
                            v-model="item.quantity"
                          />
                          <span class="input-group-prepend">
                            <button
                              type="button"
                              class="btn quantity-right-plus"
                              data-type="plus"
                              data-field
                              @click="increment(item)"
                            >
                              <i class="ti-angle-right"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-3">
                      <h2 class="td-color">
                        <a href="#" class="icon" @click="removeCartItem(item)">
                          <i class="ti-close"></i>
                        </a>
                      </h2>
                    </div>

                  </div>
                </td>
                <td>
                  <h2>{{ item.product_price }}</h2>
                </td>
                <td>
                  <h2>{{ item.point }}</h2>
                </td>
                <td>
                  <div class="qty-box">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <button
                          type="button"
                          class="btn quantity-left-minus"
                          data-type="minus"
                          data-field
                          @click="decrement(item)"
                        >
                          <i class="ti-angle-left"></i>
                        </button>
                      </span>
                      <input
                        type="text"
                        name="quantity"
                        class="form-control input-number"
                        :disabled="true"
                        v-model="item.quantity"
                      />
                      <span class="input-group-prepend">
                        <button
                          type="button"
                          class="btn quantity-right-plus"
                          data-type="plus"
                          data-field
                          @click="increment(item)"
                        >
                          <i class="ti-angle-right"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </td>
                <!--                            <td>-->
                <!--                                <a class="icon" @click="removeCartItem(item)">-->
                <!--                                    <i class="ti-close"></i>-->
                <!--                                </a>-->
                <!--                            </td>-->
                <td>
                  <h2 class="td-color">
                    {{ item.currency.symbol }}
                    {{ (item.price * item.quantity) | numeral("0,0.00") }}
                    &nbsp;<a class="icon" @click="removeCartItem(item)"><i class="ti-close"></i></a>
                  </h2>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="table cart-table table-responsive-md"
            v-if="cart.length"
          >
            <tfoot>
              <tr>
                <td style="padding: 0px;padding-top: 10px;">Prix total :</td>
                <td style="padding: 0px;padding-top: 10px;">
                  <h4>
                    {{ cartTotal | numeral("0,0.00") }} {{ cart.length > 0 ? cart[0].currency.symbol : "" }}
                  </h4>
                </td>
              </tr>
              <tr>
                <td style="padding: 0px">Total Point :</td>
                <td style="padding: 0px">
                  <h4>{{ cartTotalPoint | numeral("0,0.00") }}</h4>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="col-sm-12 empty-cart-cls text-center" v-if="!cart || !cart.length>0">


            <img :src='"@/assets/images/icon-empty-cart.png"' class="img-fluid" alt="empty cart" width="120"
                   height="auto"/>
            <h3 class="mt-3">
              <strong>Votre panier est vide</strong>
            </h3>
            <h4 class="mb-3">
              Ajouter des articles pour marquer des points, gagner des cashback
              et progresser.
            </h4>
            <div class="col-12">
              <router-link :to="{ path: '/produits' }" class="btn btn-solid"
                >Continuer a acheter
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row cart-buttons" v-if="cart.length">
        <div class="col-8">
          <router-link :to="{ path: '/produits' }" :class="'btn btn-solid btn-small'"
            >Continuer a acheter</router-link
          >
        </div>
        <div class="col-4" style="text-align: left !important;padding-left: 0px;margin-left: -5px">
          <router-link :to="{ path: '/checkout' }" :class="'btn btn-solid btn-small'"
            >Commander
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "cart",

  date() {
    return {
      counter: 1,
    };
  },

  computed: {
    ...mapGetters({
      cart: "cart/cartItems",
      cartTotal: "cart/cartTotalAmount",
      cartTotalPoint: "cart/cartTotalPoint",
    }),
  },

  methods: {
    removeCartItem: function (product) {
      this.$store.dispatch("cart/removeCartItem", product);
    },
    increment(product, qty = 1) {
      console.log('product.quantity', product.quantity)
      this.$store.dispatch("cart/updateCartQuantity", {
        product: product,
        qty: qty,
      });
    },
    decrement(product, qty = -1) {
      console.log('product.quantity', product.quantity)
      if(product.quantity>1) {
        this.$store.dispatch("cart/updateCartQuantity", {
          product: product,
          qty: qty,
        });
      }
    },
  },
};
</script>

<style scoped></style>
